export const environment = {
  production: true,
  keycloak: {
    url: '/auth',
    realm: 'noumena',
    clientId: 'nm-platform-user-client',
    enabled: true
  },
  version: '20231213r02'
};
